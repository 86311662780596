import translation from './en/translation'
import activityfeed from './en/activityfeed'
import hostedpages from './en/hostedpages'
import settings from './en/settings'
import versions from './en/versions'
import datapoints from './en/datapoints'
import cases from './en/cases'
import form from './en/form'

export const defaultNS = 'translation'

export const resources = {
  en: {
    translation,
    activityfeed,
    hostedpages,
    settings,
    versions,
    datapoints,
    cases,
    form,
  },
}