import { useStytchMember, useStytchOrganization } from '@stytch/react/b2b'
import { environment } from '@awell/libs-web/environment'
import { AppHeader as BaseAppHeader, useHeaderStyles } from '@awell/ui-kit/components/AppHeader'
import { AppSwitcher } from '@awell/ui-kit/components/AppSwitcher'
import { type SwitchableApp } from '@awell/ui-kit/components/AppSwitcher/types'
import { Breadcrumbs } from '@awell/ui-kit/components/Breadcrumbs'
import { IconButton } from '@awell/ui-kit/components/Button'
import { Edit, Help, Play, User } from '@awell/ui-kit/components/Icons'
import { IconWrapper } from '@awell/ui-kit/components/IconWrapper'
import { Menu, MenuItem } from '@awell/ui-kit/components/Menu'
import { NavigationButton } from '@awell/ui-kit/components/NavigationButton'
import { type PopoverSelectorItemType } from '@awell/ui-kit/components/PopoverSelector'
import { Tooltip } from '@awell/ui-kit/components/Tooltip'
import { useBreadcrumbs } from '@awell/ui-kit/hooks/useBreadcrumbs'
import { spacing } from '@awell/ui-kit/utils/style-guide'
import { type RouteComponentProps, useLocation, useNavigate } from '@reach/router'
import classnames from 'classnames'
import { isNil } from 'lodash'
import React, { type FC, useEffect, useState } from 'react'
import { t } from 'i18next'
import { useBackNavigationContextProvider } from '../../hooks/useBackNavigationContext'
import { usePathwayContext } from '../../hooks/usePathwayContext'
import { usePathwayNavigationInfo } from '../../hooks/usePathwayNavigationInfo'
import { builderUrlVar } from '../../local-state'
import { AppVersion } from '../AppVersion'
import { FormBuilderNavigation } from './FormBuilderNavigation'
import { PathwayCaseNavigation } from './HeaderItems/PathwayCaseNavigation'
import { PathwayTrackNavigation } from './HeaderItems/PathwayTrackNavigation'
import { MobileMenu } from './MobileMenu'
import { useSessionUrlsQuery } from './types'
import { useStyles } from './useStyles'
import { useTrackCustify } from '@awell/libs-web/analytics'
import { CustifyEvents } from '@awell/libs-web/experiments'

export const PrivateHeader: FC<RouteComponentProps> = () => {
 
  const { member } = useStytchMember()
  const { organization } = useStytchOrganization()
  const { search } = useLocation()
  const { paths, isTrackList, isBuilding, isPreviewingCase, pathwayId, isAForm, trackId, pathwayCaseId } =
    usePathwayContext()
  const { pathwayName } = usePathwayNavigationInfo(pathwayId)
  const { appHeaderWithBorder } = useHeaderStyles()
  const { disabledOnSave } = useBackNavigationContextProvider()

  const navigate = useNavigate()
  const [backUrl, setBackUrl] = useState<string | undefined>('')
  const classes = useStyles()
  const { trackCustify } = useTrackCustify()

  // In the form builder, we replace the breadcrumbs with a Go Back button
  const useQuery = (): URLSearchParams => {
    return new URLSearchParams(search)
  }
  const query = useQuery()

  const registerPathMatch = window.location.pathname.match(/\/register/)
  let isRegistering = false
  if (registerPathMatch) {
    const [register] = registerPathMatch
    isRegistering = register === '/register'
  }

  useEffect(() => {
    if (!isNil(query)) {
      setBackUrl(query.get('back_url') ?? undefined)
    }
    return () => {
      setBackUrl('')
    }
  }, [query])

  const data = useSessionUrlsQuery()
  const sessionUrls = data.data?.sessionUrls

  /**
   * We show the invite collaboration button in all pages except on the form builder,
   * and the simulation page because we have the copy_share button instead.
   *
   * The copy_share button is showed only in the simulation page because for now that is
   * what we want the user to share.
   */

  const { breadcrumbs } = useBreadcrumbs()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleLogout = (): void => {
    void navigate('/logout')
  }

  const getPreviewUrl = (): string => {
    // If the user has not accessed the preview before in the current session,
    // use the default preview path. Otherwise we bring them back to the last
    // scenario they were previewing
    if ((!isNil(sessionUrls?.preview) && !isNil(pathwayId) && sessionUrls?.preview.includes(pathwayId)) ?? false) {
      return sessionUrls?.preview ?? ''
    }
    return paths?.preview ?? ''
  }

  const switchToPreview = (): void => {
    builderUrlVar(window.location.href)
    void navigate(getPreviewUrl())
  }

  const switchToBuilder = (): void => {
    if (!isNil(paths)) {
      void navigate(paths.root)
    }
  }

  const onTrackSelect = (_track: PopoverSelectorItemType): void => {
    builderUrlVar(window.location.href)
    void navigate(`${paths?.buildRoot ?? ''}/${_track.id}`)
  }

  const onCaseSelect = (_case: PopoverSelectorItemType): void => {
    builderUrlVar(window.location.href)
    void navigate(`${getPreviewUrl()}${_case.id}`)
  }

  const handleAppSwitch = (app: SwitchableApp): void => {
    switch (app) {
      case 'home':
        void trackCustify(CustifyEvents.GO_STUDIO_TO_CAREOPS)
        void navigate(environment.urls.careops)
        break
      case 'operate':
        void trackCustify(CustifyEvents.GO_STUDIO_TO_CARE)
        void navigate(environment.urls.care)
        break
      default:
    }
  }

  const showPreviewButton = isBuilding === true && isAForm !== true

  return (
    <>
      <BaseAppHeader
        className={classnames({
          [appHeaderWithBorder]: isPreviewingCase !== true,
        })}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div data-product-tour-target="awell-logo" className={classes.logoAndBreadcrumbs}>
            {isBuilding !== true && isPreviewingCase !== true && (
              <AppSwitcher currentApp="design" onSwitch={handleAppSwitch} />
            )}
            {!isNil(backUrl) && (
              <NavigationButton linkUrl={backUrl} disabled={disabledOnSave} labelContent={<>{t('back')}</>} />
            )}
            {isNil(backUrl) && isTrackList !== true && isBuilding === true && (
              <PathwayTrackNavigation
                navigationLink={paths?.root ?? ''}
                pathwayName={pathwayName ?? ''}
                onTrackSelect={onTrackSelect}
                pathwayId={pathwayId ?? ''}
                trackId={trackId ?? ''}
              />
            )}
            {isNil(backUrl) && isTrackList !== true && isPreviewingCase === true && (
              <PathwayCaseNavigation
                navigationLink={paths?.preview ?? ''}
                pathwayName={pathwayName ?? ''}
                onCaseSelect={onCaseSelect}
                pathwayId={pathwayId ?? ''}
                pathwayCaseId={pathwayCaseId ?? ''}
              />
            )}
            {isBuilding !== true && isNil(backUrl) && isPreviewingCase !== true && (
              <div className={classes.breadcrumbs} data-cy="pathway-header-breadcrumbs">
                <Breadcrumbs list={breadcrumbs} />
              </div>
            )}
          </div>
          {isAForm === true && <FormBuilderNavigation backUrl={backUrl} />}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className={classes[isRegistering ? 'iconsUserHelpNoBorder' : 'iconsUserHelp']}>
              <Menu
                open={isMenuOpen}
                onClose={() => {
                  setIsMenuOpen(false)
                }}
                onOpen={() => {
                  setIsMenuOpen(true)
                }}
                toggleButtonContent={<User />}
                dropdownLeft
                dataCy="pathway-header-user-menu"
                data-product-tour-target="profile-btn"
                tooltipText={t('your_profile_and_settings')}
              >
                <MenuItem disabled>{t('logged_in_user_email', { email: member?.email_address })}</MenuItem>
                <MenuItem disabled>
                  {/* allows the tenant id to be copied */}
                  <span style={{ userSelect: 'text' }}>{`${organization?.organization_name ?? ''}`}</span>
                </MenuItem>
                <MenuItem dataCy="pathway-user-logout-btn" onClick={handleLogout}>
                  {t('logout')}
                </MenuItem>
                <MenuItem disabled>
                  <AppVersion />
                </MenuItem>
              </Menu>
              <Tooltip info={`${t('help')}`} arrow placement="left">
                <IconButton
                  iconSize="xsm"
                  target="_blank"
                  href={t('link_awell_help')}
                  data-product-tour-target="awell-help-center-btn"
                  rel="noopener"
                >
                  <IconWrapper size="s">
                    <Help />
                  </IconWrapper>
                </IconButton>
              </Tooltip>
            </div>
            {isPreviewingCase === true && (
              <div className={classes.previewButton}>
                <Tooltip info={`${t('edit_pathway')}`} arrow placement="left">
                  <IconButton
                    variant="accent"
                    iconSize="xsm"
                    style={{
                      minWidth: 'auto',
                      padding: spacing.xxs,
                    }}
                    onClick={() => {
                      switchToBuilder()
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            <div className={classes.mobileMenu}>
              <MobileMenu handleLogout={handleLogout} />
            </div>
            {showPreviewButton && (
              <div className={classes.previewButton}>
                <Tooltip info={`${t('view')} ${t('pathway_cases')}`} arrow placement="left">
                  <IconButton
                    variant="accent"
                    iconSize="xsm"
                    style={{
                      minWidth: 'auto',
                      padding: spacing.xxs,
                    }}
                    onClick={() => {
                      switchToPreview()
                    }}
                    data-heap="data-heap-preview-from-canvas"
                  >
                    <Play />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </BaseAppHeader>
    </>
  )
}

PrivateHeader.displayName = 'PrivateAppHeader'
