const datapoints = {
  data_catalog: 'Data catalog',
  data_catalog_search_placeholder: 'Search data point',
  data_catalog_subtitle: 'An overview of all data points in your care flow to ensure data quality',
  data_path: 'Data path',
  api_call_description:
    ' Capture the response from the API call and store the data in data points to use them in your workflow.',
  constant_hide_value: 'Conceal value',
  constant_hide_value_description:
    'The value of this constant will be hidden and encrypted when saved. This is useful for sensitive data such as API keys and tokens. A placeholder will be shown here, and this value can only be replaced, not modified, in the future.',
  constant_hide_value_tooltip: 'This value is hidden',
  collected: 'When data point is collected',
  collected_description: 'Tracks that are activated when a certain data point is collected',
  collected_trigger: '{{ title }} is collected',
  definition_id: 'Data point definition id',
  description: 'Define a key and a type for every baseline data point',
  input_description: 'Select what data you want display for your variable.',
  metadata_description: 'Enrich this data point with additional context',
  metadata_add_button_title: 'Add',
  not_collected: 'When data point is NOT collected',
  not_collected_trigger: '{{ title }} is not collected within',
  optional_parameter_description: 'A data point set as optional does not need to be filled in to start a care flow',
  possible_values: 'Possible values',
  property_label: 'Attribute',
  property_placeholder: 'Select a data point attribute',
  selector: {
    search_input: {
      placeholder: 'Search data resources',
      placeholder_with_value_type: 'Search data resources of type {{ value_type }}',
    },
    undefined: 'Select a data point',
  },
  value_type: 'Value type',
  value_type_placeholder: 'Select data point value type',
  value_type_description: 'Please note that the value type cannot be changed after the data point has been created.',
  will_be_stored_in: 'Will be stored in',
  categories: {
    api_call: 'API Call',
    api_call_status: 'API Call Status',
    calculation: 'Calculation Outputs',
    data_point: 'JSON Data Point',
    extension_action: 'Extension Action Outputs',
    extension_webhook: 'Extension Webhook',
    form: 'Form Answers',
    pathway: 'Baseline Information',
    patient_profile: 'Patient Profile',
    patient_identifier: 'Patient Identifier',
    step: 'Step Activation / Completion',
    track: 'Track Activation / Completion',
  },
  derived: {
    add: 'Add derived data point',
    created_success: 'Derived data point successfully created',
    created_error: 'Something went wrong while creating the derived data point. Please try again.',
    invalid_form_data: 'You must complete all fields to create a derived data point.',
    title: 'Add derived data point',
    json_path_placeholder: 'Please add your jsonpath here (visit https://jsonpath.com for more info)',
  },
  variable_one: 'Variable',
  variable_based_on_data_point: 'Based on a data point',
  variable_edit: 'Edit variable',
  variable_name: 'Variable name',
  variable_name_placeholder: 'Give your variable a name',
  variable_not_found_error: 'Variable not found, please remove it and add it again',
  variable_update_error: 'Something went wrong while updating variables',
  variable_update_success: 'Variable was successfully updated',
  variable_remove_error: 'Something went wrong while removing variables',
  variable_remove_success: 'Variable was successfully removed',
  variable_create_error: 'Could not create a new variable',
  variable_create: 'Create variable',
  variable_description: 'Manage variables used to create personalized messages',
  variable_empty_message: 'You haven’t created any variables yet',
  variable_does_not_exist: 'Variable does not exist',
  variable_does_not_exist_tooltip:
    "This variable doesn't exist in this care flow. Remove it and replace it with a new variable.",
  version_one: 'Version',
  variable_name_helper_text: 'First, give your variable a name so you can easily find it later.',
  variable_data_point_helper_text: 'Second, select what data you want display for your variable.',
  variable_fallback_helper_text:
    'Finally, configure an optional fallback that will be printed when there’s no data to display.',
  variable_other: 'Variables',
  variable_name_description: 'Give your variable a name so you can easily find it later.',
  variable_input_tooltip: "Type '/' to add a variable.",
  variable_not_found: 'variable_not_found',
  variable_type: 'Variable type',
  variable_type_description: 'Select your variable type.',
  variable_type_dynamic: 'Dynamic',
  variable_type_constant: 'Constant',
  variable_name_already_exists: 'This variable name is already in use. Please choose a unique name',
  variable_name_required: 'Variable name is required',
  no_constant_options:
    'Your care flow doesn\'t have any constants configured. Go to "Elements" > "Constants" to create one',
  constant_other: 'Constants',
  constant_edit: 'Edit constant',
  constant_name: 'Constant name',
  constant_name_description: 'Give your constant a name so you can easily find it later.',
  constant_updated: 'Constant successfully updated',
  constant_update_error: 'Something went wrong while updating the vonstant. Please try again.',
  constant_removed: 'Constant successfully deleted',
  constant_remove_error: 'Something went wrong while removing the constant. Please try again.',
  constant_saved: 'Constant successfully added',
  constant_create_error: 'Could not create a new constant. Please try again.',
  constant_create: 'Create constant',
  constant_description: 'Manage constants used in your care flow',
  constant_readonly_tooltip: 'Global constants cannot be deleted or edited on the care flow level.',
  global_contant_info:
    'This is a global constant. It is shared across all care flows and can only be edited or deleted at the organization level by Administrators.',
  admin_link: 'Click here to manage the global constants',
  constant_empty_message: 'You haven’t created any constants yet',
  dynamic_variable_load_error: 'Something went wrong while loading the variable',
  dynamic_variable_label_missing_error: 'Variable’s name is missing or has an error',
  dynamic_variable_data_point_missing_error: 'Data point is missing or has an error',
  dynamic_variable_data_point_property_missing_error: 'Data point attribute is missing or has an error',
  dynamic_variable_data_point_and_property_missing_error: 'Data point and attribute are missing or have errors',
  dynamic_variable_selection_create_new: 'New variable',
  dynamic_variable_selection_search_placeholder: 'Search an existing variable',
  dynamic_variable_selection_title: 'Select or create a variable',
  variable_selection: {
    empty_variables_message: 'No variable found with this name...',
    create_button: 'Create data variable',
  },
}

export default datapoints
